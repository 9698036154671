<template>
	<div class="navigation" :class="[{ 'extended' : navIsOpen }]">
		<router-link to="/#home">
			<div class="navigation_title" v-if="!navIsOpen">
				<div class="name">Attila Mucsi, PhD</div>
				<div class="description">Life Strategy & Coaching</div>
			</div>
		</router-link>

		<div class="navigation_wrapper">
			<router-link to="/#about"><div class="navigation_option nav-about">about</div></router-link>
			<router-link to="/#feelings"><div class="navigation_option nav-work">work with me</div></router-link>
			<router-link to="/#testimonials"><div class="navigation_option nav-testi">testimonials</div></router-link>
			<router-link to="/book" :class="getActiveParam('book')"><div class="navigation_option nav-book">book a call</div></router-link>
		</div>

		<div class="navigation_menu_title">
			<div class="navigation_menu_title_text" v-if="navIsOpen">
				<div class="menu_text">Menu</div>
			</div>

			<div class="navigation_menu_title_text" v-else>
				<div class="name">Attila Mucsi, PhD</div>
				<div class="description">Life Strategy & Coaching</div>
			</div>

			<div class="navigation_toggle" @click="navIsOpen = !navIsOpen">
				{{ navIsOpen ? '&#128936' : '&vellip;' }}
			</div>
		</div>

	</div>
</template>

<script>
import {ref} from 'vue'

export default {
	name: "Navigation",
	emits: ['click'],
	setup() {
		let navIsOpen = ref(false)

		const getActiveParam = (p) => {
			let params = window.location.pathname.split('/')[1]

			if (params === p) return 'nav-active'
		}

		const scrollToId = (id) => {
			document.getElementById(id).scrollIntoView();
		}

		return {
			navIsOpen,
			getActiveParam,
			scrollToId
		}
	}
}
</script>

<style lang="scss">
.navigation {
	z-index: 1;
	//overflow: hidden;

	transition: background-color 300ms;

	&:before {
		content: '';
		position: absolute;
		height: 100%;
		width: 300%;
		left: -100%;
		top: 0;
		background: white;
		z-index: -1;
	}

	.nav-book {
		background: #ffb347;
		color: white;
		transition: all 300ms;
	}
	.nav-active .nav-book {
		background: none;
		color: black !important;
		transition: all 300ms;
	}
}

@media only screen and (min-width: 1441px) {
	.navigation {
		padding: 40px 160px !important;
		max-width: 1920px;
		left: 50%;
		transform: translateX(-50%);
	}
}

@media only screen and (min-width: 1181px) {

	.navigation {
		position: fixed;
		width: 100%;
		top: 0;
		color: black;
		display: flex;
		justify-content: space-between;
		background: white;
		padding: 0 40px;

		.navigation_toggle {
			position: absolute;
			width: 2rem;
			top: 1rem;
			right: 1rem;
			display: none;
			text-align: center;
			z-index: 2;
		}

		transition: all 500ms;


		a:link,
		a:visited,
		a:focus {
			color: black;
			text-decoration: none;
			padding: 1rem;
		}

		.navigation_title {

			.name,
			.description {
				font-family: "Trebuchet MS";
				width: 100%;
				text-align: right;
			}

			.name {
				font-size: 2rem;
				margin-top: -0.5rem;
			}

			.description {
				font-variant: small-caps;
				margin-top: -0.25rem;
				font-size: 1.25rem;
			}
		}

		.navigation_wrapper {
			display: flex;
			justify-content: space-evenly;

			.navigation_option {
				font-size: 1.5rem;
				padding: 1rem;
				font-variant: small-caps;
			}
		}



		.navigation_menu_title {
			display: none;
		}
	}
}

@media only screen and (max-width: 1180px) {
	.navigation {
		background: white;
		box-shadow: 0 0 4px 0 black;
		width: 100vw;
		display: block;

		position: fixed;
		top: 0;
		left: 0;

		transform: translateY(-84%);

		transition: all 500ms;

		.navigation_menu_title {
			position: relative;
			width: 100%;
			display: flex;
			justify-content: space-between;

			top: 0;
			left: 0;

			color: black;
			padding: 1rem 2rem;
			text-align: center;

			transition: 500ms;

			.navigation_toggle {
				color: black;
				margin-top: 1.2rem;
				height: 34px;
			}

			.navigation_menu_title_text {
				padding-top: 0.4rem;
				margin-bottom: -0.4rem;
				.name,
				.description {
					font-family: "Trebuchet MS";
					text-align: right;
				}

				.name {
					font-size: 1.25rem;
				}

				.description {
					font-variant: small-caps;
					margin-top: 0;
					font-size: 1rem;
				}

				.menu_text {
					font-size: 1.25rem;
					line-height: 2.25rem;
				}
			}
		}

		.navigation_wrapper {
			font-variant: small-caps;
			background: var(--ac-primary-color);
			padding-left: 1rem;
			width: 100%;

			.navigation_option {
				color: white;
				padding: 1rem 0.25rem;
				width: 100%;
			}

			.navigation_option:not(:last-child) {
				border-bottom: 1px solid white;
			}


		}

		.navigation_toggle {
			display: block;
		}


		&.extended {
			transform: translateY(-6%);

			.navigation_menu_title {
				background: #333;
				color: white;
			}
			.navigation_toggle {
				color: white;
				margin-top: 0.75rem;
			}


		}

		a:link,
		a:visited,
		a:focus {
			color: white;
			text-decoration: none;
			padding: 1rem;
		}



		.navigation_title {
			display: none;
		}
	}
}


</style>
