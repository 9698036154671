<template>
	<div class="clearfix changes">
		<div class="changes-divider col-12 float-start my-5">
			many try small changes but then continue to give their all until…
		</div>

		<div class="clearfix changes-boxes-wrapper">
			<div class="col-12 col-md-6 float-start px-2 changes-wrapper" v-for="(f, k) in changes">
				<div class="col-12 change">
					<div class="f-title col-12">
						<div>
							{{ f.title }}
						</div>
					</div>
					<div class="col-12 f-content" v-html="f.content"></div>
					<img class="f-image" :src="require('@/assets/image/' + f.image)">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Changes",
	setup() {
		const changes = [
			{
				title: 'autopilot',
				content: 'You wake up in 5 years, feeling that you\'ve lost 5 years of your life like you were on autopilot. You deeply regret wasting those years.',
				image: 'a.svg'
			},{
				title: 'boredom',
				content: 'You feel that your life is boring, nothing interesting happens to you, and you lack the creativity and desire to create something new.',
				image: 'b.svg'
			},{
				title: 'frustration',
				content: 'You often feel very busy while experiencing a high level of stress, worry, anger, frustration, and exhaustion daily.',
				image: 'f.svg'
			},{
				title: 'indifference',
				content: 'You can reach burnout levels, where your top results will feel insignificant, and things you liked to do previously become indifferent to you.',
				image: 'i.svg'
			},{
				title: 'loneliness',
				content: 'People close to you will find someone else to connect with who gives their time and undivided attention to them.\n',
				image: 'l.svg'
			}
		]

		return {
			changes
		}
	}
}
</script>

<style lang="scss">
.changes {
	font-family: "Trebuchet MS";
	overflow: hidden;

	.changes-divider {
		white-space: nowrap;
		position: relative;
		width: 100%;
		text-align: center;
		color: white;
		background: #3ABCDA;
		font-variant: small-caps;
		padding: 3rem 0;
	}


	.changes-boxes-wrapper {
		max-width: calc(1920px - 760px);
		margin: auto;
	}
	.changes-wrapper {
		position: relative;
		.change {
			padding: 0.5cm;
			border: 1px solid black;
			margin: 0.5rem;
		}

		.f-title {
			font-variant: small-caps;
			font-size: 1.5rem;
			text-align: center;
			font-weight: bold;

			> div {
				position: relative;
				display: inline;
				&:before {
					top: 18px;
					height: 14px;

					position: absolute;
					content: 'iilittle to no fulfillmentii';
					border: 2px solid #FFC000;
					width: max-content;
					color: transparent;
					left: 50%;
					transform: translateX(-50%);
					z-index: -1;
				}
			}
		}

		.f-content {
			padding-top: 30px;
			text-align: center;
		}

		.f-image {
			position: absolute;
			transform: translate(1cm, -50%);
			left: 0;
			top: 50%;
			width: 2cm;
			height: 2cm;
		}

		&:last-child {
			top: 0.5rem;
			margin-left: auto !important;
			margin-right: auto !important;
			float: none !important;
			clear: both !important;
		}
	}

}

.changes {


	@media only screen and (max-width: 1900px) {
	}

	@media only screen and (max-width: 1439px) {
		.changes-divider {
			font-size: 1.75rem;
		}

	}

	@media only screen and (min-width: 1181px) {
		.changes-divider {
			font-size: 29pt;
		}

		.change {
			padding-left: 130px !important;
		}


	}
	@media only screen and (max-width: 500px) {
		.changes-divider {
			font-size: 1.5rem;
			white-space: pre-wrap;
			line-height: 1;
			padding: 1.3cm 0;
		}

		.changes-wrapper {
			.change {
				margin: 0.5rem 0;
			}

			.f-image {
				display: none;
			}
		}

	}
}
</style>
