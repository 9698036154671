<template>
	<div class="clearfix together">
		<div class="together-divider col-12 float-start my-5">
			HOW DO WE WORK TOGETHER?
		</div>

		<div class="clearfix together-boxes-wrapper">
			<div class="col-12 float-start px-2 together-wrapper" v-for="(f, k) in together">
				<div class="col-12 tgthr">
					<div class="f-title col-12">
						<div>
							{{ f.title }}
						</div>
					</div>
					<div class="col-12 f-content" v-html="f.content"></div>
					<img class="f-image" :src="require('@/assets/image/' + f.image)">
				</div>
			</div>
		</div>
		<div class="col-12 float-start text-center">
			<book-my-call-button/>
		</div>

	</div>
</template>


<script>
import BookMyCallButton from "@/components/BookMyCallButton";
export default {
	name: "Together",
	components: {BookMyCallButton},
	setup() {
		const together = [
			{
				title: '1. we identify your real needs',
				content: 'Many are driven by the demands of others (like peers and family), instead of looking inwards for a moment to think about what do I really need in life?',
				image: 'wiyrn.svg'
			},{
				title: '2. we set inspiring yet achievable goals',
				content: 'Sometimes the benefit of our goals is too small to make us move, other times the goal is so huge and complex that we don’t see how we’ll get there: goal setting is hard work.',
				image: 'wsiyag.svg'
			},{
				title: '3. we identify and remove your blockers',
				content: 'In many cases, You are more than capable of achieving what You want, but you may face an external or internal conflict or a disempowering belief about your options.',
				image: 'wiaryb.svg'
			},{
				title: '4. we build your habits to success',
				content: 'Success and failure don’t happen overnight; it is a process that requires you to incorporate a few good habits into your life while removing some habits that don’t serve you.',
				image: 'wbyhts.svg'
			},{
				title: '5. we measure and celebrate your victory',
				content: 'Some don’t even recognize when they have reached their goals and miss out on the joy. You must make measurable progress, appreciate small victories, and celebrate significant milestones.',
				image: 'wmacyv.svg'
			}
		]

		return {
			together
		}
	}
}
</script>

<style lang="scss">
.together {
	font-family: "Trebuchet MS";
	overflow: hidden;

	.together-divider {
		white-space: nowrap;
		position: relative;
		width: 100%;
		text-align: center;
		color: white;
		background: #3ABCDA;
	  padding: 3rem 0;
	}


	.together-boxes-wrapper {
		max-width: calc(1920px - 950px);
		margin: auto;
	}
	.together-wrapper {
		margin-bottom: 2rem;

		position: relative;
		.tgthr {
			padding-left: 5cm;
		}

		.f-title {
			font-variant: small-caps;
			font-size: 1.5rem;
			font-weight: bold;

				position: relative;
				display: inline;
				&:before {
					top: 22px;
					height: 14px;

					position: absolute;
					content: '';
					border: 2px solid #FFC000;
					background: #FFC000;
					width: 50%;
					color: transparent;
					z-index: -1;
				}

		}

		.f-content {
			padding-top: 30px;
		}

		.f-image {
			position: absolute;
			transform: translate(2cm, -50%);
			left: 0;
			top: 50%;
			width: 2cm;
			height: 2cm;
		}

		&:last-child {
			margin-left: auto !important;
			margin-right: auto !important;
			float: none !important;
			clear: both !important;
		}
	}
}

.together {


	@media only screen and (max-width: 1900px) {
	}
	@media only screen and (max-width: 1439px) {
		.together-divider {
			font-size: 1.75rem;
		}


	}

	@media only screen and (min-width: 1181px) {
		.together-divider {
			font-size: 29pt;
		}


	}
	@media only screen and (max-width: 500px) {
		.together-divider {
			font-size: 1.5rem;
		}

		.together-wrapper {
			.tgthr {
				margin: 0.5rem 0;
				padding-left: 0.5cm;
			}

			.f-image {
				display: none;
			}
		}
	}

}
</style>
