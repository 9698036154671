<template>
	<div class="footer">
		<span>&copy; Attila Mucsi COACHING PHD {{ cur_year }}</span>
	</div>
</template>

<script>
export default {
	name: "LandingFooter",
	setup() {
		const cur_year = (new Date).getFullYear()

		return {
			cur_year
		}
	}
}
</script>

<style lang="scss">
	.footer {
		color: white;
		margin-top: 3rem;
		width: 100%;
		background: #333;
		padding: 3rem 0;
		text-align: center;
		float: left;
	}
</style>
