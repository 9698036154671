<template>
	<button class="book" @click="reroute">BOOK A FREE CALL TO<br>END STRESS TODAY</button>
</template>

<script>
export default {
	name: "BookMyCallButton",
	setup() {
		const reroute = (caller) => {
			window.location.href = '/book'
		}

		return {
			reroute
		}
	}
}
</script>

<style scoped>
.book {
	background: #3ABCDA;
	font-variant: small-caps;
	color: white;
	border: none;
	padding: 20px 30px;
	margin: auto;
	display: inline-block;
}
</style>
