<template>
	<div class="clearfix home">
		<div id="home" class="hash-top"></div>

		<div class="coach-image col-12 col-md-5 float-start" :style="'background-image: url(' + require('@/assets/image/coach.png') + ')'"></div>

		<div class="coach-text col-12 col-md-7 float-start p-0 text-center">

			<div class="fw-bold text-center">
				I’m helping ambitious business professionals to<br>
				end stress TODAY while multiplying their results and improve <br>
				their quality of life without constantly stressing about work.
			</div>

			<ul class="text-start">
				<li>Rediscover your <strong>PURPOSE</strong> and gain confidence</li>
				<li>Revive your <strong>PASSION</strong> and boost your energy</li>
				<li>Increase your <strong>PRODUCTIVITY</strong> and erase stress</li>
				<li>Feel <strong>FULFILLMENT</strong> after your achievements</li>
				<li>Spend time with your <strong>FAMILY</strong> and loved ones</li>
			</ul>

			<div class="col-12 my-3 float-start"></div>

			<book-my-call-button/>
		</div>

	</div>
</template>

<script>
import BookMyCallButton from "@/components/BookMyCallButton";
export default {
	name: "Home",
	components: {BookMyCallButton}
}
</script>

<style lang="scss">
	.home {

		padding-top: 132px !important;
		max-width: calc(1920px - 320px);
		width: 100%;
		margin-left: auto;
		margin-right: auto;

		.coach-image {
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;

			height: calc(100%);
			min-height: 40vh;
		}

		.coach-text {
			font-family: "Trebuchet MS";


			div {
				line-height: 1.2;
				font-size: 20pt;
				margin-bottom: 20pt;
			}

			ul {
				margin-left: auto;
				margin-right: auto;
				width: max-content;

				position: relative;
				list-style-type: none;
				padding-left: 0 !important;

				li {
					margin-bottom: 2px;
					position: relative;
					&:before {
						font-size: 70px;
						left: -90px;
						top: -34px;
						content: '\27A1';
						position: absolute;
						color: #ffb357;
						mask-image: linear-gradient(90deg, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 50%, rgba(0,0,0,1) 100%);
					}
				}
			}
		}


		@media only screen and (max-width: 1439px) {
			padding: 8px;

			min-height: 95vh;

			.coach-text {

				div:nth-child(1) {
					margin-top: 30px !important;
				}

				ul li {
					font-size: 17px !important;
				}

				.book {
					margin-top: -24px;
					margin-bottom: 24px;
				}
			}

		}
		@media only screen and (min-width: 1440px) and (max-width: 1920px) {

			height: 90vh;

			.coach-image {
				margin-top: -80px;
			}

			.coach-text {
				margin-top: -26px;

				div {
					font-size: 2rem;
				}

				ul li {
					font-size: 18pt;
				}
			}
		}
		@media only screen and (min-width: 1920px) {
			padding-top: 160px !important;

			height: 95vh;

			.coach-image {
				margin-top: -38px;
				height: calc(90%);
			}
			.coach-text {
				margin-top: 76px;
			}
		}
	}

</style>
