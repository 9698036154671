<template>
	<div class="clearfix feelings">
		<div id="feelings" class="hash-offset"></div>

		<div class="feelings-divider col-12 float-start my-5">
			5 SIGNS OF A LIFE FULL OF STRESS
		</div>

		<div class="clearfix feelings-boxes-wrapper">
			<div class="col-12 col-md-6 float-start px-2 feelings-wrapper" v-for="(f, k) in feelings">
				<div class="col-12 feeling">
					<div class="f-title col-12">
						<div>
							{{ f.title }}
						</div>
					</div>
					<div class="col-12 f-content" v-html="f.content"></div>
					<img class="f-image" :src="require('@/assets/image/' + f.image)">
				</div>
			</div>
		</div>
	</div>
</template>


<script>
export default {
	name: "Feelings",
	setup() {
		const feelings = [
			{
				title: 'faded purpose',
				content: 'Lack of compelling future or vision.<br>No time for yourself, You are fulfilling the demands of others only.<br>You have low self-esteem and question your abilities and life goals.<br>You feel out of control and drift with the changes in the environment.',
				image: 'fp.svg'
			},{
				title: 'low energy for passions',
				content: 'Increased sensitivity to the smallest issues.<br>Want to give up your hobbies or passion projects.<br>Ignore personal health related problems and insomnia.<br>Frustration and increased likelihood of making a mistake.',
				image: 'lefp.svg'
			},{
				title: 'painful productivity',
				content: 'You keep telling yourself that you are not good enough.<br>You constantly worry about how others will deliver their part.<br>No time to think about the right approach to solving problems.<br>Never-ending backlogs, conflicting priorities, and regular overtime.',
				image: 'pp.svg'
			},{
				title: 'little to no fulfillment',
				content: 'You lack the motivation to work with others.<br>You are afraid that your work was not perfect.<br>You feel indifferent after achieving a milestone.<br>It is hard to recall what you did on a given day.',
				image: 'ltnf.svg'
			},{
				title: 'vanished personal life',
				content: 'You are afraid that you won’t have a real life.<br>You are not in the present when with loved ones.<br>You constantly ask loved ones to understand the pressure.<br>You lost touch with your partner, family, or close friends.',
				image: 'vpl.svg'
			}
		]

		return {
			feelings
		}
	}
}
</script>

<style lang="scss">
.feelings {
	font-family: "Trebuchet MS";
	overflow: hidden;

	.feelings-divider {
		white-space: nowrap;
		position: relative;
		width: 100%;
		text-align: center;
		color: white;
		background: #3ABCDA;
		font-variant: small-caps;
		padding: 3rem 0;
		font-size: 28pt;

		margin-top: 0 !important;
	}


	.feelings-boxes-wrapper {
		max-width: calc(1920px - 640px);
		margin: auto;
	}
	.feelings-wrapper {

		position: relative;
		.feeling {
			padding: 0.5cm;
			border: 1px solid black;
			margin: 0.5rem;
		}

		.f-title {
			font-variant: small-caps;
			font-size: 1.5rem;
			text-align: center;
			font-weight: bold;

			> div {
				position: relative;
				display: inline;
				&:before {
					top: 18px;
					height: 14px;

					position: absolute;
					content: 'iilittle to no fulfillmentii';
					border: 2px solid #FFC000;
					width: max-content;
					color: transparent;
					left: 50%;
					transform: translateX(-50%);
					z-index: -1;
				}
			}
		}

		.f-content {
			padding-top: 30px;
			text-align: center;
		}

		.f-image {
			position: absolute;
			transform: translate(0.5cm, 0.4cm);
			left: 0;
			top: 0;
			width: 2cm;
			height: 2cm;
		}

		&:last-child {
			top: 0.5rem;
			margin-left: auto !important;
			margin-right: auto !important;
			float: none !important;
			clear: both !important;
		}
	}

}

.feelings {
	@media only screen and (max-width: 1439px) {
		.feelings-divider {
			font-size: 1.5rem;
		}

		.feelings-wrapper {
			.feeling {
				margin: 0.5rem 0;
			}

			.f-image {
				display: none;
			}
		}
	}
	@media only screen and (min-width: 1440px) and (max-width: 1920px) {
	}
	@media only screen and (min-width: 1921px) {
	}

}
</style>
