import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/LandingPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/LandingPage.vue')
  },{
    path: '/book',
    name: 'book',
    component: () => import(/* webpackChunkName: "about" */ '../views/Booking.vue')
  }
]

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      // BEFORE:
      // return { selector: to.hash }

      return { el: to.hash }
    }
  },
  history: createWebHistory(),
  routes


})

export default router
