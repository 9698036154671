<template>
	<div class="clearfix about">
		<div id="about" class="hash-offset"></div>

		<div class="coach-image col-12 col-md-6 float-start" :style="'background-image: url(' + require('@/assets/image/coach_2.jpg') + ')'"></div>

		<div class="coach-text col-12 col-md-6 float-start">
			<div class="float-start" style="font-size: 24px; font-weight: bold">Hello, my name is Attila.</div>
			<div class="float-start">I’ve been fascinated by the psychology of high-performing individuals since my teenage years. It is a great feeling to excel in any area of life however I noticed that not many people have the time or energy to enjoy the fruits of their hard work. It seemed unfair at first glance, but then I thought – who makes the decisions in my life? It is always my decision – what I should do and what I will do, it’s up to me.</div>
			<div class="float-start">I believe giving your best is crucial, but if You give ALL you have eventually there’ll be nothing left to give. I realized this the hard way while working a very demanding corporate job and pursuing my doctoral degree in parallel for 4 years. Also, over the last 15 years, I watched dozens of my dearest and most talented colleagues quitting jobs before they could reach their full potential or abandoning passions due to lack of time.</div>
			<div class="float-start">It doesn’t have to be YOU.</div>
			<div class="float-start">You have the talent, You are hard-working, but to scale your results and enjoy life to the fullest, You must start working harder on yourself than on your job. Yes, that means setting high-quality goals for your professional and private life as well; and working towards them consistently.</div>
			<div class="float-start">Soon you’ll see the benefits of your renewed growth mindset – at least I did, and so did the people I’ve helped as a life coach. Allow me to save a few years (or a decade) of pondering for You. It would be my privilege to work together and guide You toward the life You meant to live now.</div>
		</div>

		<div class="col-12 float-start text-center my-5 last-phar">
			YOU DESERVE A PRODUCTIVE LIFE.<br>
			YOU DESERVE A GOOD LIFE.<br>
			I’M HERE TO HELP YOU
		</div>

		<div class="col-12 float-start text-center">
			<book-my-call-button/>
		</div>

	</div>
</template>

<script>
import BookMyCallButton from "@/components/BookMyCallButton";
export default {
	name: "About",
	components: {BookMyCallButton},
}
</script>

<style lang="scss">
.about {

	* {
		font-family: "Trebuchet MS" !important;
	}

	max-width: calc(1920px - 600px);
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	.coach-image {
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;

		height: calc(100%);
		min-height: 40vh;
	}

	.coach-text {
		padding: 0 20px;

		div:not(:last-child) {
			margin-bottom: 17px;
		}
	}


	@media only screen and (max-width: 1439px) {
		padding: 8px;

		min-height: 95vh;

		.last-phar {
			font-size: 24px !important;
		}

		.coach-text {

		}

	}
	@media only screen and (min-width: 1440px) and (max-width: 1920px) {
		margin-top: 40px;

		.coach-image {
			margin-top: 10px;
			height: 100vh;
		}

		.last-phar {
			font-size: 28pt;
		}
		height: 85vh;

	}
	@media only screen and (min-width: 1920px) {
		height: 95vh;

		.coach-image {
			margin-top: 0;
			height: calc(74%);
		}
		.coach-text {

		}
	}
}
</style>
