<template>
	<div class="clearfix what-people-say">
		<div id="testimonials" class="hash-offset"></div>
		<div class="col-12 wps-title my-5">
			<!--what people say...-->
			<!--IF YOU RECOGNIZED THESE FEELINGS
			<br class="d-block d-md-none">
			THERE IS A WAY BACK TO YOUR LIFE-->
			TESTIMONIALS - SUCCESS STORIES
		</div>

		<carousel class="clearfix" :items-to-show="slides" :autoplay="3500" :transition="500" :mouseDrag="false" :touchDrag="false" :wrapAround="true" :pauseAutoplayOnHover="true">
			<slide v-for="p in people" :key="p">
				<div class="col-12 float-start clearfix c-content">
					<div class="col-12 float-start" v-html="p.content"></div>
					<div class="col-12 float-start my-5 c-content-footer">
						<strong>{{ p.name }}</strong>
						<br>
						<strong>{{ p.role }}</strong>
						<br>
						<strong>{{ p.country }}</strong>
					</div>
				</div>
			</slide>

			<template #addons>
				<navigation />
				<pagination />
			</template>
		</carousel>


		<div class="col-12 float-start text-center my-3">
			<book-my-call-button/>
		</div>
	</div>
</template>

<script>

import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import {nextTick, onMounted, ref} from "vue";
import BookMyCallButton from "@/components/BookMyCallButton";


export default {
	name: "People",
	components: {
		BookMyCallButton,
		Carousel,
		Slide,
		Pagination,
		Navigation,
	},
	setup() {
		const slide = 1
		let slides = ref(1)
		const people = ref([
			{
				name: 'Krystelle',
				city: '',
				country: 'USA',
				role: 'Business Intelligence Analyst',
				content: '<div class="carousel-content-title">I am much more vocal with team members and I\'m expressing my ideas with confidence.</div> <br> Before starting these sessions, I was really concerned about how my ideas would be viewed, but now I understand the value I add with my unique perspective. Attila has the experience to guide others, he’s been through it, and he could empathize with what I was going through beyond just the goals I set. He can help You understand where the gaps are, what roadblocks might be preventing You from achieving the life you dream about, and also help You set up a pathway to that dream.',
			},{
				name: 'Bernadette',
				city: 'St. Petersburg',
				country: 'Hungary',
				role: 'Training manager',
				content: '<div class="carousel-content-title">Finally, I\'m taking action.</div> <br> I\'ve been thinking about a career change for years, and I was extremely picky, so nothing was good enough: probably because I didn\'t want it badly enough, it was still too comfortable. With Attila\'s help, I pushed myself through the mental obstacles to honestly look at what I wanted and why I couldn\'t do it before. His framework is so result-oriented that it ended my analysis paralysis. He found my weak spots and guided me to recognize how can I turn those into strengths. I no longer accept a mediocre life; because I aim to live the best version of my life, what I deserve.',
			},{
				name: 'Maleek',
				city: 'New York',
				country: 'USA',
				role: 'Operations specialist',
				content: '<div class="carousel-content-title">I now have a winner\'s mentality.</div> <br> Instead of being trapped on the same problem for weeks or months, I can solve it in a matter of hours or minutes. Attila has a pleasant attitude, but he is obsessed with the outcomes of each session. I didn\'t feel like Attila was coaching me; rather, I felt like I was visiting a friend who was getting caught up, someone who is watching out for me. I learned to think about the present, what I can do and what I can do right now, and this gives me a sense of control over my life. I would recommend Attila to anyone looking to make a significant change in their life.',
			}
		])

		onMounted(() => {
			nextTick(() => {
				window.addEventListener('resize', () => {
					slides.value = window.innerWidth > 800 ? 1 : 1
				})
				window.addEventListener('load', () => {
					slides.value = window.innerWidth > 800 ? 1 : 1
				})
			})
		})

		return {
			people,
			slide,
			slides
		}
	}
}
</script>

<style lang="scss">
.what-people-say {

	.carousel-content-title {
		width: 100%;
		font-style: italic;
		text-align: center;
	}

	.wps-title {
		/*text-align: center;
		margin: 2rem 0;
		font-size: 2rem;
		font-variant: small-caps;*/
		white-space: nowrap;
		position: relative;
		width: 100%;
		text-align: center;
		color: white;
		background: #3ABCDA;
	  padding: 3rem 0;
	}

	.c-content {
		line-height: 1.4;
	}

	.carousel {
		max-width: calc(1160px);
		margin: auto;

		.c-content {
			text-align: left;
			padding: 0 30px;
		}

		.c-content-footer {
			text-align: center;
		}

		.carousel__prev,
		.carousel__next {
			display: none;
		}


		.carousel__pagination {
			padding-left: 0 !important;
		}
		.carousel__pagination-button {
			border-radius: 100%;
			width: 12px;
			height: 12px;
			background: gray;
		}

		.carousel__pagination-button--active {
			background: #FFC000;
		}
	}
}

@media only screen and (min-width: 1920px) {
	.wps-title {
		font-size: 28pt;
	}
}
@media only screen and (min-width: 1440px) {
	.wps-title {
		font-size: 28pt;
	}
}
@media only screen and (min-width: 1000px) {
	.wps-title {
		font-size: 28pt;
	}
}
@media only screen and (min-width: 501px) {
	.wps-title {
		font-size: 28pt;
	}
}
@media only screen and (max-width: 500px) {
	.wps-title {
		font-size: 1.5rem;
	}
}
</style>
