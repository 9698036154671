<template>
	<div class="col-12 float-start clearfix testimonial">
		<div class="testimonial-divider col-12 float-start my-5">
			IF YOU RECOGNIZED THESE FEELINGS – THERE IS A WAY BACK TO YOUR LIFE
		</div>

		<!--<div class="clearfix testimonial-boxes-wrapper">
			<div class="col-12 float-start px-2 testimonial-wrapper" v-for="(f, k) in testimonial">
				<div class="col-12 tstmnl">
					<div class="f-title col-12">
						<div>
							{{ f.title }}
						</div>
					</div>
					<div class="col-12 f-content" v-html="f.content"></div>
					<img class="f-image" :src="require('@/assets/image/' + f.image)">
				</div>
			</div>
		</div>-->

	</div>
</template>


<script>
export default {
	name: "Testimonial",
}
</script>

<style lang="scss">
.testimonial {
	font-family: "Trebuchet MS";
	overflow: hidden;

	.testimonial-divider {
		white-space: nowrap;
		position: relative;
		width: 100%;
		text-align: center;
		color: white;
		background: #3ABCDA;
		padding: 3rem 0;
	}


	.testimonial-boxes-wrapper {
		max-width: calc(1920px - 320px);
		margin: auto;
	}
	.testimonial-wrapper {
		margin-bottom: 2rem;

		position: relative;
		.tstmnl {
			padding: 0.5cm;
			margin: 0.5rem;
			padding-left: 5cm;
		}

		.f-title {
			font-variant: small-caps;
			font-size: 1.5rem;
			font-weight: bold;

				position: relative;
				display: inline;
				&:before {
					top: 25px;
					height: 14px;

					position: absolute;
					content: '';
					border: 2px solid #FFC000;
					background: #FFC000;
					width: 50%;
					color: transparent;
					z-index: -1;
				}

		}

		.f-content {
			padding-top: 30px;
			font-size: 1.5rem;
		}

		.f-image {
			position: absolute;
			transform: translate(2cm, -50%);
			left: 0;
			top: 50%;
			width: 2cm;
			height: 2cm;
		}

		&:last-child {
			margin-left: auto !important;
			margin-right: auto !important;
			float: none !important;
			clear: both !important;
		}
	}

	.carousel__pagination {
		padding-left: 0;
	}

}

.testimonial {


	@media only screen and (max-width: 1900px) {
		.f-content {
			font-size: 1.3rem !important;
		}
	}
	@media only screen and (max-width: 1439px) {
		.testimonial-divider {
			font-size: 1.75rem;

		}
	}



	@media only screen and (min-width: 1181px) {
		.testimonial-divider {
			font-size: 28pt;
		}


	}
	@media only screen and (max-width: 500px) {
		.testimonial-divider {
			font-size: 1.5rem;
			white-space: pre-wrap;
			line-height: 1;
			padding: 1.1cm 0.2cm;
		}
	}

}
</style>
