<template>
	<div class="clearfix name-of-the-game">
		<div class="name-of-the-game-divider col-12 float-start my-5">
			<div class="t-1">THE NAME OF THE GAME IS RESULTS</div>
			<div class="t-2">
				I’m helping hard-working business professionals to discover<br>
				what they really want and guide them to get there.
			</div>
		</div>

		<div class="clearfix name-of-the-game-boxes-wrapper">
			<div class="col-12 col-md-6 float-start px-2 name-of-the-game-wrapper" v-for="(f, k) in notgs">
				<div class="col-12 notg">
					<div class="f-title col-12">
						<div>
							{{ f.title }}
						</div>
					</div>
					<div class="col-12 f-content" v-html="f.content"></div>
					<img class="f-image" :src="require('@/assets/image/' + f.image)">
				</div>
			</div>
		</div>
	</div>
</template>


<script>
export default {
	name: "NameOfTheGame",
	setup() {
		const notgs = [
			{
				title: 'clear path to purpose',
				content: 'Have a sense of freedom. <br> Learn new things and grow your skillset. <br> Be self-aware and in control of your life. <br> Make an impact and contribute to society.',
				image: 'dpym.svg'
			},{
				title: 'high energy for passions',
				content: 'Feel motivated to get out of bed. <br> Have time to take care of yourself. <br> High energy levels throughout the day. <br> Spend time on hobbies/passion projects.',
				image: 'hefp.svg'
			},{
				title: 'increased productivity',
				content: 'Excel at what You do. <br> Become an expert in your field. <br> Work smarter and more efficiently. <br> Create a trustful work environment.',
				image: 'ip.svg'
			},{
				title: 'sense of fulfillment',
				content: 'Be recognized and rewarded. <br> Feel stress-free and relaxed. <br> Celebrate small victories on the path. <br> Feel good after significant achievements.',
				image: 'sof.svg'
			},{
				title: 'joyful personal life',
				content: 'Spend time with Your family. <br> Spend time with Your loved ones. <br> Get some well-deserved rest to recharge. <br> Get away from work without feeling guilt.',
				image: 'jpl.svg'
			}
		]

		return {
			notgs
		}
	}
}
</script>

<style lang="scss">
.name-of-the-game {
	font-family: "Trebuchet MS";
	overflow: hidden;

	.name-of-the-game-divider {
		padding: 3rem 0;
		white-space: nowrap;
		position: relative;
		width: 100%;
		text-align: center;
		color: white;
		background: #3ABCDA;
	}


	.name-of-the-game-boxes-wrapper {
		max-width: calc(1920px - 700px);
		margin: auto;
	}
	.name-of-the-game-wrapper {
		position: relative;
		.notg {
			padding: 0.5cm;
			border: 1px solid black;
			margin: 0.5rem;
		}

		.f-title {
			font-variant: small-caps;
			font-size: 1.5rem;
			text-align: center;
			font-weight: bold;

			> div {
				position: relative;
				display: inline;
				&:before {
					top: 18px;
					height: 14px;

					position: absolute;
					content: 'iideep purpose: your missionii';
					border: 2px solid #FFC000;
					background: #FFC000;
					width: max-content;
					color: transparent;
					left: 50%;
					transform: translateX(-50%);
					z-index: -1;
				}
			}
		}

		.f-content {
			padding-top: 30px;
			text-align: center;
		}

		.f-image {
			position: absolute;
			transform: translate(1cm, -50%);
			left: 0;
			top: 50%;
			width: 2cm;
			height: 2cm;
		}

		&:last-child {
			top: 0.5rem;
			margin-left: auto !important;
			margin-right: auto !important;
			float: none !important;
			clear: both !important;
		}
	}
}

.name-of-the-game {




	@media only screen and (max-width: 1439px) {
		.name-of-the-game-wrapper {
			.notg {
				margin: 0.5rem 0;
			}

			.f-image {
				display: none;
			}
		}

		.t-1 {
			font-size: 1.3rem;
		}

		.t-2 {
			font-size: 1rem;
			width: 100%;
			margin-top: 1rem;
			white-space: pre-wrap;
		}
	}
}

@media only screen and (min-width: 1440px) and (max-width: 1920px) {
	.name-of-the-game-wrapper {
		.notg {
			padding-left: 140px !important;
		}
	}
	.t-1 {
		font-size: 28pt;
	}

	.t-2 {
		font-size: 18pt;
		width: 100%;
		margin-top: 1rem;
	}
}
@media only screen and (min-width: 1920px) {
}
</style>
