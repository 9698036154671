import { createApp } from 'vue'

import App from './App.vue'
import router from './router'

import Hotjar from 'vue-hotjar'
import VueGtag from "vue-gtag";
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/style/main.scss'



createApp(App)
    .use(router)
    .use(Hotjar, {id: '3152529'})
    .use(VueGtag, {
        config: {
            id: "G-6Q79DT9CPH",
            params: {
                anonymize_ip: true
            }
        }
    })
    .mount('#app')
