<template>
	<navigation/>
	<home/>
	<feelings/>
	<name-of-the-game/>
	<together/>
	<changes/>
	<people/>
	<about/>
	<landing-footer/>
</template>

<script>
import Home from "@/components/Home";
import Navigation from "@/components/Navigation";
import Feelings from "@/components/Feelings";
import NameOfTheGame from "@/components/NameOfTheGame";
import Together from "@/components/Together";
import People from "@/components/People";
import Changes from "@/components/Changes";
import Testimonial from "@/components/Testimonials";
import About from "@/components/About";
import LandingFooter from "@/components/LandingFooter";

export default {
	components: {LandingFooter, About, Testimonial, Changes, People, Together, NameOfTheGame, Feelings, Navigation, Home}
}
</script>

<style lang="scss">

</style>
